.banner {
  background-color: rgba(215, 255, 94, 1);
  color: rgba(97, 61, 147, 1);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.7rem;
  text-align: center;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  max-height: 56px;
  z-index: 10;
  text-decoration: underline;
}

.banner:hover {
  background-color: rgba(196, 234, 53, 1);
  color: rgba(76, 47, 128, 1);
}



@media screen and (max-width: 500px) {
  .banner {
    font-size: 0.8rem;
    text-wrap: balance;
    text-decoration: underline;
  }
}
